import React, { Component } from 'react';
import { useState } from "react"
import axios from "axios"
import ImageUploading from 'react-images-uploading';

const ImageUpload = () => {

  const [selectedFile, setSelectedFile] = useState();

  const uploadImage = async(file) => {
    const API_JWT = process.env.REACT_APP_PINATE_JWT

    const formData = new FormData();
    
    formData.append('file', file)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);
    
    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);

    try{
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          "Authorization": API_JWT
        }
      });
      window.uploadPhotoHash = res.data.IpfsHash
    } catch (error) {
      console.log(error);
    }
  }


  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);

    if (imageList.length) {
      // data for submit
      const file = imageList[addUpdateIndex].file;
      // Upload image to ipsf
      uploadImage(file)
    }
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={imageList.length ? { display: 'none' } : undefined}
              className='black-button'
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload Image
            </button>
            &nbsp;
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                <div className="image-item__btn-wrapper">
                  <button className='black-button' onClick={() => onImageUpdate(index)}>Replace</button>
                  <button className='black-button' onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  )
}

export default ImageUpload