import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Web3 from 'web3'
import './App.css';
import UniqueItem from '../abis/UniqueItem.json'
import FileUpload from './FileUpload'
import ImageUpload from './ImageUpload'
import axios from "axios"

require('dotenv').config()

// Holds object with all addresses and all items under specific owner
window.allItems = {}
window.allActiveItemsCount = 0;

Modal.setAppElement('#root');

class App extends Component {

  async componentWillMount() {


    await this.loadWeb3()
    await this.loadBlockchainData()
    await this.loadAllItems()

  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    try {
      const web3 = window.web3
      // Load account
      const accounts = await web3.eth.getAccounts()
      this.setState({ account: accounts[0] })
      const networkId = await web3.eth.net.getId()
      const networkData = UniqueItem.networks[networkId]
      const abi = UniqueItem.abi
      const address = process.env.REACT_APP_UNIQUEITEM_SC_ADDRESS
      const contract = new web3.eth.Contract(abi, address)
      window.contract = contract;
      const totalSupply = await contract.methods.totalSupply().call()
      window.totalSupply = totalSupply.toNumber()
    } catch(e) {
      alert("Are you sure you are on the right network and SC is deployed and defined in ENV?")
    }

  }

  async updateTotalSuply() {
    const totalSupply = await window.contract.methods.totalSupply().call()
    window.totalSupply = totalSupply.toNumber()
    this.setState({ totalSupply: window.totalSupply })
  }

  async getItemHistory(itemId) {
    const historyItems = []
    const ownerHistoryCount = await window.contract.methods.getOwnersCount(itemId).call()
    for (var i = 0; i < ownerHistoryCount; i++) {
      const singleOwnership = await window.contract.methods.getOwnerInfo(itemId, i).call()
      const ownerAddress = singleOwnership.substring(0, 42)
      const ownerTimestamp = singleOwnership.substring(42)
      const formattedTimestamp = new Date(ownerTimestamp * 1000).toLocaleDateString('en-GB')
      historyItems.push({
        owner: ownerAddress,
        fromTimestamp: ownerTimestamp,
        fromFormatted: formattedTimestamp
      })
    }
   
    return historyItems
  }

  async loadAllItems() {
    // Make sure total suply is updated
    await this.updateTotalSuply();

    // Reset main object and prepare to be populated
    this.state.allItems = {}
    window.allActiveItemsCount = 0

    // Load all items
    for (var i = 1; i < window.totalSupply; i++) {
      try {
        const itemIndex = i
        const owner = await window.contract.methods.ownerOf(i).call()
        const tokenURI = await window.contract.methods.tokenURI(i).call()
        const ownerHistory = await this.getItemHistory(itemIndex)
        const metadata = await this.retrieveMetadata(tokenURI)
        const imageUrl = this.buildDisplayImageUrl(metadata.image)
  
        // Create new key and items array if owner doesn't exist yet
        if (!(owner in this.state.allItems)) {
          this.state.allItems[owner] = []
        }
  
        // Push item to owners list of items
        this.state.allItems[owner].push({
          itemIndex,
          metadata,
          tokenURI,
          imageUrl,
          owner,
          ownerHistory
        })

        window.allActiveItemsCount++
      } catch(e) {
        console.log(e)
      }
    }

    this.setState({ allItems: this.state.allItems })
    this.loadMyItems()
  }

  async loadMyItems() {
    if (this.state.account in this.state.allItems) {
      this.setState({ myItems: this.state.allItems[this.state.account] })
    } else {
      this.setState({ myItems: [] })
    }
  }

  async retrieveMetadata(hash) {
    try {
      const config = {
        method: 'get',
        url: 'https://gateway.pinata.cloud/ipfs/' + hash,
        headers: { 
          'Content-Type': 'application/json', 
        },
      }
      
      const res = await axios(config)
  
     return res.data
    } catch(e) {
      console.log(e)
    }
  }

  buildDisplayImageUrl(url) {
    const hash = url.replace("ipfs://", "")
    return "https://gateway.pinata.cloud/ipfs/" + hash
  }


  mint = async (title, description, photoHash) => {
    this.showLoader()

    const metadata = await this.generateMetadata(title, description, photoHash)
   
    window.contract.methods.safeMint(metadata).send({ from: this.state.account })
    .once('confirmation', (confirmation) => {
      this.loadAllItems()
      this.closeLoader()
      this.closeMintModal()
    })
    .catch((err) => {
      console.log(err)
      this.closeLoader()
    })

  }

  generateMetadata = async (name, description, photoHash) => {
    try{
      const API_JWT = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIyOTIyMDgyZC0wZTA0LTRiYTQtYmU0YS0zMzNkNjMzNzY3ZWIiLCJlbWFpbCI6InNhYmFub3YudGltQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiIxNjQxNjUzYzY3OGU4NGU2MDVmZSIsInNjb3BlZEtleVNlY3JldCI6IjNhMzAyMGU4ZTRmM2NhOGI0YWRjZDUxNWQyYTM1NDc1Yjk1Mzg5ZTI2N2MwYmQ1ZTk2NTcyNjYwYTViMzJjY2EiLCJpYXQiOjE2NzU1MTkzNTh9.qVU3Moee1YiebMaKTOA2urd4d88uEs_9ulU30Z9aDKQ"

      const data = JSON.stringify({
        "name": name,
        "description": description,
        "image": "ipfs://" + photoHash,
      })

      const config = {
        method: 'post',
        url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': API_JWT
        },
        data : data
      }
      
      const res = await axios(config);

      window.metadataHash = res.data.IpfsHash
      return res.data.IpfsHash;
    } catch (error) {
      console.log(error);
    }
  }

  displayItem(item) {
    this.setState({ selectedItem: item })
    this.openModal()
  }

  openModal() {
    this.setState({ showModal: true })
  }

  afterOpenModal() {
   
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  openMintModal() {
    this.setState({ showMintModal: true })
  }

  afterMintModalOpen() {
   
  }

  closeMintModal() {
    this.setState({ showMintModal: false })
  }


  showLoader() {
    this.setState({ showLoader: true })
  }

  closeLoader() {
    this.setState({ showLoader: false })
  }

  initiateTransfer() {
    this.setState({ showTransferForm: true })
  }

  doneWithTransfer() {
    this.setState({ showTransferForm: false })
  }

  async transferItem(toAddress, tokenId) {
    this.showLoader()
    window.contract.methods.ownerTransfer(this.state.account, toAddress, tokenId).send({ from: this.state.account })
    .once('confirmation', (confirmation) => {
      // Requery and display all items
      this.loadAllItems()
      // Reset selected items
      this.setState({ selectedItem: null })
      this.setState({ transferTo: "" })

      // Close Loader and modal
      this.closeLoader()
      this.closeModal()

      // Transfer cleanup
      this.doneWithTransfer()
    })
    .catch((err) => {
      console.log(err)
      this.closeLoader()
    })
  }

  async burnItem(tokenId) {
    this.showLoader()
    window.contract.methods.burn(tokenId).send({ from: this.state.account })
    .once('confirmation', (confirmation) => {
      // Requery and display all items
      this.loadAllItems()
      // Reset selected items
      this.setState({ selectedItem: null })
      this.setState({ transferTo: "" })

      // Close Loader and modal
      this.closeLoader()
      this.closeModal()
    })
    .catch((err) => {
      console.log(err)
      this.closeLoader()
    })
  }

  transferSelectChanged(event) {
    this.setState({transferTo: event.target.value})
  }

  switcher(value) {
    this.setState({ showItems: value })
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      contract: null,
      totalSupply: 0,
      colors: [],
      allItems: {},
      myItems: [],
      showLoader: false,
      showModal: false,
      showMintModal: false,
      selectedItem: null,
      showTransferForm: false,
      transferTo: "",
      showItems: "all"
    }
  }

  render() {
    return (
      <div>
        { this.state.showLoader && 
          <div className="loading">Loading&#8230;</div>
        }
        
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <a className="navbar-brand col-sm-3 col-md-2 mr-0"
            href="/"
            target="_blank"
            rel="noopener noreferrer" >BOOMBA</a>
          <ul className="navbar-nav">
            <li className="nav-item text-nowrap d-sm-block">
              <button onClick={() => this.openMintModal()} className="close-button">MINT NEW ITEM</button>
              <small className="text-white d-none d-sm-none"><span id="account">{this.state.account}</span></small>
            </li>
          </ul>
        </nav>

        <div className='container switcher'>
          <div className='row'>
            <div className='col-12'>
              <button onClick={() => this.switcher('all')} 
                    className={
                      (this.state.showItems === "all") ? 'switch-button active' : 'switch-button'
                    }>ALL ITEMS ({ window.allActiveItemsCount })</button>
              <button onClick={() => this.switcher('mine')} 
                    className={
                      (this.state.showItems === "mine") ? 'switch-button active' : 'switch-button'
                    }>MY ITEMS ({ this.state.myItems.length })</button>
            </div>
          </div>
        </div>



        <Modal
        isOpen={ this.state.showMintModal }
        onAfterOpen={ this.afterMintModalOpen }
        onRequestClose={ () => this.closeMintModal() }
        contentLabel="Example Modal"
        >
        <div className="container-fluid mt-5">
         <button onClick={() => this.closeMintModal()} className="close-button modal-close-button">CLOSE</button>
          <div className="row">
            <main role="main" className="col-lg-12 d-flex text-center mintform-holder">
              <div className="content mr-auto ml-auto">
                <h2>Mint new item</h2>
                <ImageUpload></ImageUpload>
                <form onSubmit={(event) => {
                  event.preventDefault()
                  const title = this.title.value
                  const description = this.description.value
                  const photoHash = window.uploadPhotoHash
                  this.mint(title, description, photoHash)
                }}>
                  <input
                    type='text'
                    className='form-control mb-1'
                    placeholder='Item title'
                    ref={(input) => { this.title = input }}
                  />
                  <input
                    type='text'
                    className='form-control mb-1'
                    placeholder='Item Description'
                    ref={(input) => { this.description = input }}
                  />
                  <input
                    type='submit'
                    className='black-button'
                    value='MINT ITEM'
                  />
                </form>
              </div>
            </main>
          </div>
        </div>
        </Modal>

        <Modal
        isOpen={ this.state.showModal }
        onAfterOpen={ this.afterOpenModal }
        onRequestClose={ () => this.closeModal() }
        contentLabel="Example Modal"
        >
        {
          this.state.selectedItem &&
          <div className="modelContent container-fluid">
            <button onClick={() => this.closeModal()} className="close-button modal-close-button">CLOSE</button>
            <div className='row'>
              <div className="column col-12 col-md-6 detail-column">
                <h2>{ this.state.selectedItem.metadata.name }</h2>
                <img src={ this.state.selectedItem.imageUrl } />
                <p>{ this.state.selectedItem.metadata.description }</p>
                <div className="owner">Owner: { this.state.selectedItem.owner }</div>
              </div>
              { this.state.showTransferForm ?
                <div className="column col-12 col-md-6 transfer-column">
                  <h2>Transfer item</h2>
                  <form className="transferForm" onSubmit={(event) => {
                    event.preventDefault()
                    this.transferItem(this.state.transferTo, this.state.selectedItem.itemIndex)
                  }}>
                    <div className='newRow'>
                      <div className='title'>Select recipient</div>
                      <select className='select' name="transferTo" value={this.state.transferTo} onChange={this.transferSelectChanged.bind(this)}>
                          <option key="null" value="">-- Select --</option>
                          { Object.keys(this.state.allItems).map((key) => (
                            (key !== this.state.account)  && 
                              <option key={key} value={key}>{key}</option>
                              ))
                          }
                      </select>
                    </div>
                    <div className='newRow'>
                      <input
                        type='submit'
                        className='black-button'
                        value='CONFIRM TRANSFER'
                      />
                    </div>
                   
                  </form>
                </div>
                :
                <div className="column col-12 col-md-6 ownership-column">
                  <h2>Ownership history</h2>
                  <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Owner</th>
                          <th scope="col">From</th>
                        </tr>
                      </thead>
                      <tbody>
                      { this.state.selectedItem.ownerHistory && this.state.selectedItem.ownerHistory.map((item, key) => {
                        return(
                          <tr>
                                <th scope="row">{key}</th>
                                <td>{item.owner}</td>
                                <td>{item.fromFormatted}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>

                </div>
              }

            </div>

            <div className='row buttons-row'>
              <div className='column col-6 col-xs-12'>
                
              </div>
              <div className='column col-6 col-xs-12'>
              { (!this.state.showTransferForm && this.state.selectedItem.owner === this.state.account) &&
                <button onClick={() => this.initiateTransfer()} className="transfer-button">TRANSFER</button>
              }
              { (!this.state.showTransferForm && this.state.selectedItem.owner === this.state.account) &&
                <button onClick={() => this.burnItem(this.state.selectedItem.itemIndex)} className="transfer-button">BURN</button>
              }
              </div>
            </div>
            


          </div>
        }

      </Modal>

        { this.state.showItems === "all" &&
          <div className="container mt-5">
            <div className="row text-center">
            
            { Object.keys(this.state.allItems).map((key) => (

              this.state.allItems[key] && this.state.allItems[key].map((item, key) => {
                return(
                  <div className='col-md-3'>
                    <figure key={key} className="snip1527" onClick={() => this.displayItem(item)}>
                      <div className="image" style={{
                        backgroundImage: "url(" + item.imageUrl + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                      }}>

                      </div>
                      <figcaption>
                        <div className="date">{item.owner}</div>
                        <h3>{item.metadata.name}</h3>
                        <p>
                          {item.metadata.description}
                        </p>
                      </figcaption>
                    </figure>
                  </div>


                )
              })
            ))}
            </div>
          </div>
        }

        { this.state.showItems === "mine" &&
            <div className="container mt-5">
              <div className="row text-center">
              
              { this.state.myItems && this.state.myItems.map((item, key) => {
                  return(
                    <div className='col-md-3'>
                      <figure key={key} className="snip1527" onClick={() => this.displayItem(item)}>
                        <div className="image" style={{
                          backgroundImage: "url(" + item.imageUrl + ")",
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat'
                        }}>

                        </div>
                        <figcaption>
                          <div className="date">{item.owner}</div>
                          <h3>{item.metadata.name}</h3>
                          <p>
                            {item.metadata.description}
                          </p>
                        </figcaption>
                      </figure>
                    </div>
                  )
                })}
              </div>
            </div>
        }





      </div>
    );
  }
}

export default App;